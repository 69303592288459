import React from "react";
import { EReportTypes } from "../routes/report_types";
import {
  getFullSummaryConfig,
  ReportSummaryConfig,
  ReportSummaryConfigBase,
} from "../summary/types";
import { BaseColumnType } from "../columns/config/types";
import { ReportFilterConfigBase } from "../filters/config/types";
import {
  getFullColumnConfig,
  ReportColumnConfig,
  ReportColumnConfigBase,
} from "../columns/config/ReportColumnConfig";
import { ReportFilters } from "../filters/config/ReportFilters";
import { SubReportsConfig, SubReportsConfigBase } from "../sub-report/types";
import { BulkAction as BaseBulkAction } from "../../BulkActionBar";

export interface BulkAction extends BaseBulkAction {
  refreshOnSuccess?: boolean;
}

export interface BulkActionConfigBase<
  T extends Record<string, unknown> = Record<string, unknown>,
> {
  prepareLabel: (selected: T[]) => React.ReactNode,
  actions: BulkAction[],
}

export interface ReportConfigBase<
  T extends Record<string, unknown> = Record<string, unknown>,
> {
  type: (typeof EReportTypes)[keyof typeof EReportTypes];
  endpoint: string;
  reportKey?: string;
  summaryKey?: string;
  filtersBase: ReportFilterConfigBase[];
  summaryBase: ReportSummaryConfigBase[] | null;
  columnsBase: ReportColumnConfigBase<BaseColumnType>[];
  intro: {
    description: string;
    url: string;
  };
  exportUrl?: string;
  withSelection?: boolean;
  resultsPerPage?: number;
  bulkActions?: BulkActionConfigBase<T>;
  subReports?: SubReportsConfigBase;
  refetchOnFilterChange?: boolean;
}

export interface ReportConfig
  extends Omit<
    ReportConfigBase,
    "filtersBase" | "summaryBase" | "columnsBase" | "subReports"
  > {
  filters: ReportFilters;
  summary: ReportSummaryConfig[] | null;
  columns: ReportColumnConfig<BaseColumnType>[];
  subReports?: SubReportsConfig;
}

export const getReportConfig = (type: string): ReportConfig | null => {
  try {
    const config: ReportConfigBase = require(`./${type.toLowerCase()}`).default;
    return parseReportConfig(config);
  } catch (e) {
    console.log(
      `Failed to load report config for ${type}, make sure the file exists(lowercase) and is properly exported`,
    );
    return null;
  }
};

const DEFAULT_REPORT_KEY = "report";
const DEFAULT_SUMMARY_KEY = "totals";

export const parseReportConfig = (
  reportConfigBase: ReportConfigBase,
): ReportConfig => {
  const {
    filtersBase,
    summaryBase,
    columnsBase,
    subReports,
    reportKey,
    summaryKey,
    ...rest
  } = reportConfigBase;

  return {
    ...rest,
    reportKey: reportKey ?? DEFAULT_REPORT_KEY,
    summaryKey: summaryKey ?? DEFAULT_SUMMARY_KEY,
    filters: new ReportFilters(filtersBase),
    summary: summaryBase ? getFullSummaryConfig(summaryBase) : null,
    columns: getFullColumnConfig(columnsBase),
    subReports: subReports ? new SubReportsConfig(subReports) : undefined,
  };
};

export { STYLE_CONSTANTS, ReportElementSizes } from "./styles";
