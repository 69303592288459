import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";

const ORepColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Avatar> = {
  header: "O Rep",
  type: BASE_COLUMN_TYPES.Avatar,
  fields: [
    "sales_rep_user_id",
    "sales_rep_first_name",
    "sales_rep_last_name",
    "sales_rep_avatar_file_id",
  ],
  accessorKey: "sales_rep",
  canSort: true,
  orderByField: "sales_rep",
  width: 73,
  dataTransform: ({
    sales_rep_first_name,
    sales_rep_last_name,
    sales_rep_avatar_file_id,
  }) => ({
    full_name: `${sales_rep_first_name} ${sales_rep_last_name}`,
    avatar_file_id: sales_rep_avatar_file_id,
  }),
};

const CRepColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Avatar> = {
  header: "C Rep",
  type: BASE_COLUMN_TYPES.Avatar,
  fields: [
    "client_rep_user_id",
    "client_rep_first_name",
    "client_rep_last_name",
    "client_rep_avatar_file_id",
  ],
  accessorKey: "client_rep",
  canSort: true,
  orderByField: "client_rep",
  width: 73,
  dataTransform: ({
    client_rep_first_name,
    client_rep_last_name,
    client_rep_avatar_file_id,
  }) => ({
    full_name: `${client_rep_first_name} ${client_rep_last_name}`,
    avatar_file_id: client_rep_avatar_file_id,
  }),
};

const ProjectNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Link
> = {
  header: "Project",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_number"],
  accessorKey: "job_number",
  canSort: true,
  orderByField: "job_number",
  width: 83,
  dataTransform: ({ job_number }) => ({
    href: `/project/${job_number}`,
    text: `#${job_number}`,
  }),
};

const ProjectNameColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Link> =
{
  header: "Name",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_name", "job_number"],
  accessorKey: "job_name",
  canSort: true,
  orderByField: "job_name",
  width: 150,
 textOptions: {
    truncate: true,
 },
  dataTransform: ({ job_name, job_number }) => ({
    href: `/project/${job_number}`,
    text: job_name,
  }),
};

const ClientColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Link> = {
  header: "Client",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["client_id", "client_name"],
  accessorKey: "client",
  canSort: true,
  orderByField: "client_name",
  width: 136,
 textOptions: {
    truncate: true,
 },
  dataTransform: ({ client_id, client_name }) => ({
    href: `/client.php?id=${client_id}`,
    text: client_name,
  }),
  // linkAction: ({ client_id }) => console.log(client_id), // change to open clientSidePanel
};

const DateColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Date> = {
  header: "Date",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["date_orderdate"],
  accessorKey: "date_orderdate",
  canSort: true,
  orderByField: "order_date",
  width: 120,
};

const BudgetColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: "Budget",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["total_budget", "currency_id"],
  accessorKey: "budget",
  canSort: true,
  orderByField: "order_budget",
  width: 100,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ total_budget, currency_id }) => ({
    amount: total_budget,
    currency_id,
  }),
};

const SubtotalColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: "Subtotal",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["order_type", "total_subtotal", "currency_id"],
  accessorKey: "subtotal",
  canSort: true,
  orderByField: "order_subtotal",
  width: 95,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ order_type, total_subtotal, currency_id }) => ({
    amount: ["ESTIMATE", "SALES ORDER", "INVOICE"].includes(order_type) ? total_subtotal : null,
    currency_id,
  }),
};

const GrossMarginColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: "Gross\nMargin",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["order_type", "total_gross_margin_amount", "currency_id"],
  accessorKey: "gross_margin",
  canSort: true,
  orderByField: "total_gross_margin_amount",
  moneyOptions: { decimalPlaces: 2 },
  width: 93,
  dataTransform: ({ order_type, total_gross_margin_amount, currency_id }) => ({
    amount: ["ESTIMATE", "SALES ORDER", "INVOICE"].includes(order_type) ? total_gross_margin_amount : null,
    currency_id,
  }),
};

const GrossMarginPercentageColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Percentage
> = {
  header: "Gross\nMargin %",
  type: BASE_COLUMN_TYPES.Percentage,
  fields: ["form_number", "order_margin"],
  accessorKey: "gross_margin_percentage",
  canSort: true,
  orderByField: "order_margin",
  percentageOptions: { decimalPlaces: 2 },
  width: 91,
  dataTransform: ({ order_type, order_margin }) => ({
    percentage: ["ESTIMATE", "SALES ORDER", "INVOICE"].includes(order_type) ? order_margin : null
  })
};

const StatusColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Status> = {
  header: "Form",
  type: BASE_COLUMN_TYPES.Status,
  fields: ["order_type", "status_name", "form_number", "job_number"],
  accessorKey: "form",
  canSort: true,
  orderByField: "order_status",
  width: 177,
  dataTransform: ({ order_type, status_name, form_number, job_number }) => ({
    order_type,
    form_number,
    status_name,
    href: `/project/${job_number}${order_type === "OPPORTUNITY"
      ? ""
      : `/${order_type
        .split(" ")
        .map((o: string) => o.toLowerCase())
        .join("-")}/${form_number}`
      } `,
  }),
};

const NotesColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Notes> = {
  header: "Notes",
  type: BASE_COLUMN_TYPES.Notes,
  fields: ["num_notes", "job_id", "job_number"],
  accessorKey: "notes",
  canSort: true,
  orderByField: "num_notes",
  width: 64,
  dataTransform: ({ num_notes, job_id, job_number }) => ({
    withNotes: num_notes > 0,
    job_id,
    job_number,
    href: `/project/${job_number}`,
  }),
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  ProjectNumberColumn,
  CRepColumn,
  ORepColumn,
  ProjectNameColumn,
  ClientColumn,
  DateColumn,
  BudgetColumn,
  SubtotalColumn,
  GrossMarginColumn,
  GrossMarginPercentageColumn,
  StatusColumn,
  NotesColumn,
];
